import React, { useCallback, useMemo } from 'react'
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import { useStoreon } from 'storeon/react'
import { node } from 'prop-types'
// import { useAuth } from '@h4b-dev/hooks'

const { H4B_GRAPHQL_URL: url } = h4benv

const GraphQLProvider = ({ children }) => {
  const { user, app, dispatch } = useStoreon('user', 'app')
  // const { verifyUser } = useAuth()

  const headers = useMemo(() => {
    const h = {}
    if (app.id) {
      h['X-App-Id'] = app.id
    }
    if (app.stores?.[app.current.storeIndex]?.locations?.[app.current.locationIndex]) {
      h['X-Location'] = app.stores[app.current.storeIndex].locations[app.current.locationIndex].id
    }
    if (user?.geo?.longitude && user?.geo?.latitude) {
      h['X-Geo-Longitude'] = user.geo.longitude
      h['X-Geo-Latitude'] = user.geo.latitude
    }
    if (user?.accessToken) {
      h.Authorization = `Bearer ${user.accessToken}`
    }
    return h
  }, [app, user])

  /*
  const retryAfterUnauthorized = useCallback(async () => {
    if (!user.refreshToken) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
      throw new Error('No refresh token available')
    }

    const response = await verifyUser({
      id_token: user.idToken,
      refresh_token: user.refreshToken,
      access_token: user.accessToken,
    })

    dispatch('user/set', {
      ...response.tokens,
      authUser: {
        ...response.user,
      },
    })

    return response.tokens.accessToken
  }, [user, verifyUser, dispatch])

  const customFetch = useCallback(
    async (input, init) => {
      const makeRequest = async (accessToken) => {
        const retryHeaders = {
          ...init.headers,
          Authorization: `Bearer ${accessToken}`,
        }
        return fetch(input, { ...init, headers: retryHeaders })
      }

      let response = await makeRequest(user.accessToken)

      if (response.status === 401) {
        const newAccessToken = await retryAfterUnauthorized()
        response = await makeRequest(newAccessToken)
      }

      if (response.ok) {
        const json = await response.clone().json()
        if (
          json.errors?.some(
            (error) => error.extensions?.code === 'AUTH_NOT_AUTHORIZED',
          )
        ) {
          const newAccessToken = await retryAfterUnauthorized()
          response = await makeRequest(newAccessToken)
        }
      }

      return response
    },
    [retryAfterUnauthorized, user.accessToken],
  )
  */

  const client = useMemo(
    () => new GraphQLClient({
      url,
      headers,
      // fetch: customFetch,
    }),
    [url, headers],
  )

  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  )
}

GraphQLProvider.propTypes = {
  children: node.isRequired,
}

export default GraphQLProvider

