import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { StoreContext } from 'storeon/react'
import store from '@store'
import AuthProvider from '@hooks/AuthProvider'
import * as Sentry from '@sentry/react'
import { FullLoading } from '@hb/web-components'
import GraphQLProvider from './hooks/GraphQLProvider'

import Page from './pages'
import Error from './pages/Error/Error'

// reset css styles
import 'minireset.css'
import './styles/global.css'

const {
  H4B_AUTH_DOMAIN: domain,
  H4B_AUTH_CLIENTID: clientId,
  H4B_AUTH_AUDIENCE: audience,
} = h4benv

const SentryPage = Sentry.withProfiler(Page, { name: 'SentryPage' })

// eslint-disable-next-line react/prop-types
const App = () => (
  <Sentry.ErrorBoundary fallback={<Error />} showDialog>
    <StoreContext.Provider value={store}>
      <AuthProvider config={{ domain, clientId, audience }}>
        <GraphQLProvider>
          <Suspense fallback={<FullLoading />}>
            <SentryPage />
          </Suspense>
        </GraphQLProvider>
      </AuthProvider>
    </StoreContext.Provider>
  </Sentry.ErrorBoundary>
)

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<App />)
